.content-wrapper {
  margin: 0 auto;
  max-width: 1500px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0.5rem;
}

.news-card {
  border: 0px solid aqua;
  margin: 0.5rem;
  position: relative;
  width: 30%;
  height: 12rem;
  overflow: hidden;
  border-radius: 0.5rem;
  flex: 1;
  min-width: 290px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@media (min-width: 900px) {
  .news-card {
    height: 20rem;
  }
}

.news-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0)
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 80%);
  z-index: 0;
}

.news-card__card-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /*     background: rgba(255,0,0,.5); */
}

.news-card__image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  transition: transform 3s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  z-index: -1;
}

.news-card__text-wrapper {
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  left: 0rem;
  padding: 1rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  transition: background-color 1.5s ease;
}

.news-card__title {
  transition: color 1s ease;
  margin-bottom: 0.5rem;
  color: white;
}

.news-card__post-date {
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  color: #ccc;
}

.news-card__details-wrapper {
  max-height: 0;
  opacity: 0;
  transition: max-height 1.5s ease, opacity 1s ease;
}

@media (min-width: 900px) {
  .news-card:hover .news-card__details-wrapper {
    max-height: 20rem;
    opacity: 1;
  }
  .news-card:hover .news-card__text-wrapper {
    background-color: rgba(0, 0, 0, 0.6);
  }
  .news-card:hover .news-card__title {
    color: yellow;
  }
  .news-card:hover .news-card__image {
    transform: scale(1.2);
    z-index: -1;
  }
}

.news-card__excerpt {
  font-weight: 300;
}

.news-card__read-more {
  background: black;
  color: #bbb;
  display: block;
  padding: 0.4rem 0.6rem;
  border-radius: 0.3rem;
  margin-top: 1rem;
  border: 1px solid #444;
  font-size: 0.8rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-decoration: none;
  width: 7rem;
  margin-left: auto;
  position: relative;
  z-index: 5;
}

.news-card__read-more i {
  position: relative;
  left: 0.2rem;
  color: #888;
  transition: left 0.5s ease, color 0.6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.news-card__read-more:hover i {
  left: 0.5rem;
  color: yellow;
}

.searchbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
  width: 100%;
  border-radius: 25px;
  border: 2px solid #73ad21;
}

.searchbox > input {
  width: 100%;
}

.hero-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroimage {
  margin: 20px;
  height: 30rem;
  width: 100%;
}

h1 {
  font-weight: bold;
  margin: 1rem;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.signin-button,
.order-summary-button {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  padding: 8px 38px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  text-align: center;
}

.placeorder-action {
  border: 2px black solid;
  padding: 3em 10em;
}

.signin-button:active {
  transform: scale(0.95);
}

.signin-button:focus {
  outline: none;
}

.signin-button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}
/* 
form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
} */

.sigin-input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 50%;
}

.sigin-input-register {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
.shop-search-button-red {
  padding: 0.8rem;
  margin-left: 1rem;
  border-radius: 6px;
  background-color: #001659;
  color: white;
}

.user-sign-new-button {
  background-color: white;
}

/* .container {
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
} */

.form-container {
  /* position: absolute; */
  /* top: 0; */
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #ff416c;
  background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.form_container {
  display: flex;
  justify-content: center;
  width: 100vw;
  padding-top: 5rem;
}

.checkout-step {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.line-active {
  margin: 1em 10em;
  padding: 2px;
  color: #ff4b2b;
}

.line-inactive {
  margin: 1em 10em;
  color: grey;
  padding: 2px;
}

.edit-product-icon:hover,
.delete-product-icon:hover {
  cursor: pointer;
}

.delete-product-icon {
  color: red;
}

.edit-product-icon {
  color: #001659;
  margin-right: 1rem;
}

.product-table-container {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-table {
  align-items: center;
  justify-content: center;
  border: solid 0.05px grey;
  border-radius: 1.5rem;
}

.product-table > tr {
  margin: 1em;
}

.product-table-td {
  margin: 2rem;
  width: 5%;
  padding: 1.2rem;
  border: solid 0.05px grey;
}

.product-table-cart {
  text-align: center;
  margin: 0.2rem;
  min-width: 10%;
  padding: 1.2rem;
  border: solid 0.05px grey;
}

.card-margin {
  padding: 1rem;
  margin-top: 1rem;
  width: 40%;
  /* border: 1px solid;
  border-radius: 0.75rem; */
}

.cart-card {
  padding: 0.2rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.cart-button {
  border-radius: 2px;
  border: 1px solid #001659;
  background-color: #001659;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  width: 30%;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  margin: 0 auto;
}

.cart-button:active {
  transform: scale(0.95);
}

.cart-button:focus {
  outline: none;
}

.cart-button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.shop-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.icons {
  font-size: 24px;
}

.placeorder-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-wallet-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 30vw;
}

.shop-menu-signup-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  margin: 0 1em;
  background-color: #001659;
  color: #ffc54f;
}

.shop-menu-signup-button:active,
.shop-menu-signup-button:hover {
  background-color: #ffc54f;
}

.shop-menu-signup-button-menu {
  background-color: #ffc54f;
}

.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #ffc54f !important;
  border-color: #ffc54f !important;
}

.create-your-own-shop {
  color: black;
}
.create-your-own-shop:hover {
  color: grey;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  animation: spin infinite 5s linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slide-image {
  width: 85%;
}

@media only screen and (max-width: 1200px) {
  .slide-des h4 {
    font-size: 15px;
  }
  .slide-des h1 {
    font-size: 30px;
  }
  .slide-des h3 {
    font-size: 20px;
  }
  .slide-image {
    width: 65%;
  }
}
@media only screen and (max-width: 1055px) {
  .slide-des h4 {
    font-size: 15px;
  }
  .slide-des h1 {
    font-size: 30px;
    margin-top: -25px;
    margin-bottom: -10px;
  }
  .slide-des h3 {
    font-size: 20px;
  }
  .slide-image {
    width: 55%;
  }
}
@media only screen and (max-width: 765px) {
  .slide-des {
    padding: 0% !important;
    padding-left: 6% !important;
    text-align: center !important;
    padding-bottom: 30px !important;
  }
  .slide-image {
    display: none;
  }
  .icons {
    font-size: 14px;
  }
}

.place_order_container {
  margin: 3em;
}

.placeorder {
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.place-order-title {
  font-size: 1.6em;
}

.placeorder-title-container {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 2em 6em;
}

.shipping_cart_product_container {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 7em 1em 1em;
  flex-direction: row;
  color: black;
  border-radius: 25px;
  background-color: hsl(40, 95%, 92%);
  width: 100%;
  margin-top: 2em;
}

.shipping_cart_product_container:hover {
  background-color: hsl(40, 87%, 73%);
  cursor: pointer;
}

.cart-list-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 37vw;
}

.privacy-policy {
  margin: 2rem;
  text-align: justify;
}
.searchbox {
  width: 52%;
  border-radius: 0.25rem;
  border: none;
  align-items: center;
  justify-content: space-around;
  display: flex;
}

.searchbox-input {
  height: 3rem;
  padding-left: 0.25rem;
  width: 40%;
  border: 1px solid #444;
  border-width: 0.1rem;
  border-radius: 0.25rem;
}

.shop-search-button {
  padding: 0.8rem;
  margin-left: 1rem;
  border-radius: 6px;
  background-color: #ffc54f;
  color: #001659;
}

.shop-signin-button {
  padding: 0.4 0.8rem;
  margin-left: 1rem;
  border-radius: 6px;
  background-color: #ffc54f;
  color: #001659;
}

.shop-search-button:hover {
  background-color: #001659;
  color: white;
}

.center-description{
  margin-top: 4rem;
}
@media screen and (max-width: 480px) {
  .searchbox {
    width: 90%;
  }
  .explore-leftside {
    padding-left: 3rem;
  }
}

.service-details-plumbing {
  background-color: #ffc54f;
  display: block;
  font-weight: 600;
  line-height: 50px;
  padding: 10px 28px;
  position: relative;
  color: #000;
  font-size: 18px;
  border: none;
  margin: 10px 0;
}

.service-details-plumbing:hover {
  color: white;
}

.footer-logo {
  height: 100px;
  width: 150px;
}

.section-padding-less {
  padding-top: 3rem;
  padding-bottom: 2.8rem;
}

.section-padding-less-cta {
  padding-top: 3rem;
  padding-bottom: 2.8rem;
  margin-right: 3rem;
}

.header-logo3 {
  height: 103px;
  width: 150px;
}

.member-img {
  object-fit: cover;
  height: 25.625rem;
  width: 25.625rem;
}

.member-image-size {
  height: 25.625rem;
  width: 25.625rem;
}

.oue-experise {
  padding: 0;
  color: orangered;
}
.space-cart {
  padding-right: 1rem;
}

.product-detail-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.product-detail-image-container {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.product-detail-heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.product-detail-button{
  background-color:#001659;
  color: white;
  padding: 0.5rem;
  text-align: center;
  margin-top: 1rem;
}

.product-detail-description{
  text-align: justify;
  margin-top: 1rem;
}

.product-detail-description-heading{
  padding: 0.3rem;
  border-radius: 0.25rem;
  background-color: orange;
  color: white;
  margin-top: 1rem;
  text-align: center;
}

.product-detail-image{
  height: 40rem;
  width: 40rem;
}