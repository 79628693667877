.grid-container {
    display: grid;
    column-gap: 20px;
    row-gap: 50px;
    grid-template-columns: auto auto auto auto;
    /* background-color: #2196F3; */
    /* padding: 10px; */
    margin: 2px 50px 
    
  }
  
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    /* padding: 20px;
    font-size: 30px; */
    text-align: center;
  }

  p{
      margin: 0;
      padding: 0;
  }
  @media only screen and (max-width: 600px) {
    .grid-container {
        grid-template-columns: auto ;
    }
  }