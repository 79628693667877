.testimonial-nav {
    a {
        background: #fafafa;
        display: block;
        margin-top: 30px;
        border-radius: 0px !important;
        padding: 30px;
        box-sizing: border-box;

        @media (max-width: 767px) {
            padding: 20px 30px;
        }

        &.active {
            background-color: #fff !important;
            color: $theme-color-3 !important;
            border-right: 4px solid $theme-color-3;
        }

        .single-client-tab {
            .profile-img {
                border-radius: 50%;
                width: 60px;
                height: 60px;
                background: #eee;
                float: left;
                overflow: hidden;
                margin-right: 20px;
                text-align: center;
                background-position: center;
                background-size: cover;
            }

            .client-info {
                overflow: auto;
                padding-top: 5px;

                h3 {
                    font-size: 18px;
                    line-height: 24px;
                    color: $second-color;
                }
            }
        }
    }
}

.testimonial-contents {
    .tab-pane {
        margin-top: 30px;

        .single-testimonial-content {
            .engginer-img {
                border-style: solid;
                border-width: 10px;
                border-color: #fff;
                background-color: #eee;
                box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.04);
                width: 330px;
                height: 415px;
                overflow: hidden;
                float: left;
                margin-right: 50px;

                @media (max-width: 767px) {
                    width: 250px;
                    height: 280px;
                    float: inherit;
                    display: block;
                    margin: 0 auto;
                }

                img {
                    margin-top: 20px;

                    @media (max-width: 767px) {
                        margin-top: 0;
                    }
                }
            }

            .content {
                overflow: auto;
                padding-top: 60px;

                @media (max-width: 767px) {
                    width: 100%;
                    text-align: center;
                    padding-top: 30px;
                }

                .rating-star {
                    i,
                    span {
                        color: #ffa200;
                        transition: all 0.3s ease-in-out;
                    }

                    margin-bottom: 15px;
                }

                h3 {
                    font-size: 22px;
                    line-height: 150%;
                    font-weight: 500;
                    color: $second-color;

                    @media (max-width: 767px) {
                        font-size: 20px;
                    }
                }

                .theme-btn {
                    margin-top: 35px;
                }
            }
        }
    }
}

.brand-logo-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
